import React, { FC, useContext } from 'react'
import dynamic from 'next/dynamic'
import 'antd/dist/antd.css'
import { Layout } from 'antd'
import 'nprogress/nprogress.css'
import { AdminProvider } from './AdminContext'
import { Container } from './Admin/Container'
import { Content } from './Admin/Content'
import { Sider } from './Admin/Sider'
import { Header } from './Admin/Header'

const { Footer } = Layout

const ContentProgressBar = dynamic(
  () => {
    return import('./components/ContentProgressBar')
  },
  { ssr: false }
)

const Admin: FC = ({ children }) => {
  return (
    <AdminProvider>
      <Layout style={{ minHeight: '100vh' }}>
        <ContentProgressBar></ContentProgressBar>
        <Sider />
        <Container>
          <Header style={{ position: 'fixed' }} />
          <Content>{children}</Content>
          <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
        </Container>
      </Layout>
    </AdminProvider>
  )
}

export default Admin
