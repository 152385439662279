import Layout from 'antd/lib/layout/layout'
import { FC, useContext } from 'react'
import { AdminContext } from '../AdminContext'

export const Container: FC = ({ children, ...rest }) => {
  const { siderWidthClosed, siderWidthOpen, siderColapsed } = useContext(AdminContext)
  return (
    <Layout
      className={''}
      style={{
        marginLeft: siderColapsed ? siderWidthClosed : siderWidthOpen,
        transitionDuration: '300ms',
      }}
    >
      {children}
    </Layout>
  )
}
