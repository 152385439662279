import { FC, useContext } from 'react'
import { Layout } from 'antd'
import { AdminContext } from '../AdminContext'
import styles from './Content.module.less'

const AntdContent = Layout.Content

export const Content: FC = ({ children, ...rest }) => {
  const { contentPadding } = useContext(AdminContext)
  return (
    <AntdContent style={{ padding: contentPadding }} {...rest}>
      <div className={styles['site-layout-content']}>{children}</div>
    </AntdContent>
  )
}
