import { useState, createContext, FC } from 'react'

export interface IAdminContext {
  siderColapsed: boolean
  setSiderColapsed: Function
  siderWidthOpen: number
  siderWidthClosed: number
  headerHeight: number
  contentPadding: number
}

export const AdminContext = createContext({} as IAdminContext)

export const AdminProvider: FC = ({ children }) => {
  const [siderColapsed, setSiderColapsed] = useState(false)
  return (
    <AdminContext.Provider
      value={{
        siderColapsed,
        setSiderColapsed,
        siderWidthOpen: 200,
        siderWidthClosed: 80,
        headerHeight: 64,
        contentPadding: 20,
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}
