import React, { FC } from 'react'
import Link from 'next/link'

interface LogoProps {
  href?: string
}
const Logo: FC<LogoProps> = ({ href = '/' }) => {
  return (
    <Link href={href}>
      <img
        className="mx-auto h-12 w-auto"
        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
        alt="Workflow"
      />
    </Link>
  )
}

export default Logo
