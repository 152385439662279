import React, { useContext } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Layout, message, Row, Dropdown } from 'antd'
import styles from './Header.module.less'
import { AdminContext } from '../AdminContext'

const { SubMenu } = Menu
const AntdHeader = Layout.Header

function handleMenuClick<MenuClickEventHandler>(info: any) {
  message.info('Click on menu item.')
  console.log('click', info)
}

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1" icon={<UserOutlined />}>
      1st menu item
    </Menu.Item>
    <Menu.Item key="2" icon={<UserOutlined />}>
      2nd menu item
    </Menu.Item>
    <Menu.Item key="3" icon={<UserOutlined />}>
      3rd menu item
    </Menu.Item>
  </Menu>
)
//width: `calc(100% - ${siderColapsed ? siderWidthClosed + 'px' : siderWidthOpen + 'px'})`,
export const Header = ({ ...rest }) => {
  const { siderWidthClosed, siderWidthOpen, siderColapsed } = useContext(AdminContext)
  return (
    <AntdHeader
      className={styles.header}
      {...rest}
      style={{
        position: 'sticky',
        top: 0,
        display: 'flex',
      }}
    >
      <Row align={'middle'} justify={'end'} style={{ width: '100%' }}>
        <Dropdown.Button overlay={menu} placement="bottomLeft" icon={<UserOutlined />}>
          Dropdown
        </Dropdown.Button>
      </Row>
    </AntdHeader>
  )
}
