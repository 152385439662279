import Admin from '../ui/layout/Admin'

const IndexPage = () => {
  return (
    <p style={{ display: 'block', height: '2000px'}}>admin</p>
  )
}

IndexPage.Layout = Admin;

export default IndexPage
