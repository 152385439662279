import React, { FC, useState, useContext } from 'react'
import Link from 'next/link'
import { SiderProps } from 'antd/lib/layout/Sider'
import {
  UserOutlined,
  PieChartOutlined,
  DesktopOutlined,
  TeamOutlined,
  FileOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import Logo from '../../../components/Logo'
import { AdminContext } from '../AdminContext'
import styles from './Sider.module.less'

const { SubMenu } = Menu
const AntdSider = Layout.Sider

export const Sider: FC<SiderProps> = ({ ...rest }) => {
  const { siderWidthOpen, siderColapsed, setSiderColapsed } = useContext(AdminContext)

  return (
    <AntdSider
      width={siderWidthOpen}
      collapsible
      collapsed={siderColapsed}
      onCollapse={(collapsed: boolean) => setSiderColapsed(collapsed)}
      style={{
        zIndex: 2,
        position: 'fixed',
      }}
      {...rest}
    >
      <div className={styles.brand}>
        <Logo></Logo>
      </div>
      <div className={styles.menuContainer}>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
          <Menu.Item key="1" icon={<PieChartOutlined />}>
            <Link href="/users">Users</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<DesktopOutlined />}>
            Option 2
          </Menu.Item>
          <SubMenu key="sub1" icon={<UserOutlined />} title="User">
            <Menu.Item key="3">Tom</Menu.Item>
            <Menu.Item key="4">Bill</Menu.Item>
            <Menu.Item key="5">Alex</Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
            <Menu.Item key="6">Team 1</Menu.Item>
            <Menu.Item key="8">Team 2</Menu.Item>
          </SubMenu>
          <Menu.Item key="9" icon={<FileOutlined />}>
            Files
          </Menu.Item>
        </Menu>
      </div>
    </AntdSider>
  )
}
